import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const SimpleReactLightbox = makeShortcode("SimpleReactLightbox");
const LightBox = makeShortcode("LightBox");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <SimpleReactLightbox mdxType="SimpleReactLightbox">
  <LightBox mdxType="LightBox">
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Daria" modelName="Daria Zakharova" place="Moscow" filename="daria_zakharova001.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Laura" modelName="Laura Sormunen" place="Finland" filename="laura.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Butterfly" modelName="Daria" place="Barcelona" filename="butterfly.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Ekaterina" modelName="Ekaterina" place="Barcelona" filename="Ekaterina_3_chimeneas_Barcelona-1-3.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Anna" modelName="Anna" place="Barcelona" filename="Anna--2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Stella" modelName="Stella" place="Moscow" filename="stella.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Jessica" modelName="Jessica Anne Sanford" place="Barcelona" filename="jessicaphotography-by-andrey-bond-2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </LightBox>
    </SimpleReactLightbox>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      